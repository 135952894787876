.timerItem{
    display: flex;
    align-items: flex-end;
    gap: 2px;
    user-select: none;
}

.timerItem-number{
    font-family: "Montserrat ExtraBold", sans-serif;
    font-size: 40px;
    line-height: 32px;
    margin-right: 4px;
    color: var(--main-blue);
}

.timerItem-text{
    font-family: "Montserrat Medium", sans-serif;
    font-size: 16px;
    line-height: 18px;
}

@media (max-width: 1450px) and (min-width: 1067px) {
    .timerItem-number{
        font-size: 36px;
        line-height: 28px;
    }

    .timerItem-text{
        font-size: 15px;
        line-height: 16px;
    }
}

@media (max-width: 619px) {
    .timerItem-number{
        font-size: 36px;
        line-height: 28px;
    }

    .timerItem-text{
        font-size: 15px;
        line-height: 16px;
    }
}
