.clipboard{
    width: 100%;
}

.clipboard-label{
    font-family: "Montserrat SemiBold", sans-serif;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    color: var(--main-blue);
    margin-bottom: 8px;
    user-select: none;
}

.clipboard-input{
    position: relative;
    height: 25px;
    width: calc(100% - 77px);
    backdrop-filter: blur(43px);
    background: rgba(255, 255, 255, 0.01);
    border: 1px solid var(--main-blue);
    padding: 16px 55px 16px 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.clipboard-text{
    font-family: "Montserrat Regular", sans-serif;
    font-size: 15px;
    line-height: 18px;
    color: var(--main-blue);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
}

.clipboard-input::after{
    content: url("../../assets/copy.svg");
    position: absolute;
    width: 15px;
    height: 16px;
    top: calc(50% - 8px);
    right: 21px;
}
