.timer {
    display: flex;
    gap: 40px;
}

@media (max-width: 1450px) {
    .timer{
        gap: 20px;
    }
}

@media (max-width: 1067px) {
    .timer{
        flex-direction: column;
        gap: 56px;
    }
}

@media (max-width: 619px) {
    .timer{
        gap: 45px;
    }
}
