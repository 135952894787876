.container {
    position: relative;
    max-width: 1504px;
    margin: 0 auto;
    padding: 159px 32px 21px 32px ;
    display: grid;
    grid-template-areas:
        "head head . img"
        "desc desc . img"
        "timer timer . img"
        "address address . img"
        "key key . img";
    grid-template-columns: 396px 396px auto 589px;
    min-height: 100%;
}

.content{
    width: 608px;
}

.logo{
    display: block;
    width: 86px;
    height: 32px;
    margin-bottom: 30px;
    user-select: none;
    -webkit-user-drag: none;
    background: url(/src/assets/logo.svg) no-repeat;
    color: transparent;
}

.header {
    font-family: "Montserrat ExtraBold", sans-serif;
    font-weight: 800;
    font-size: 80px;
    line-height: 98px;
    margin: 0 0 16px 0;
    color: var(--main-blue);
}

.mainPage-lang{
    position: absolute;
    top: 159px;
    right: 32px;
    z-index: 2;
}

.mainPage-subHeader{
    font-size: 27px;
    line-height: 48px;
    font-family: "Montserrat ExtraBold", sans-serif;
    font-weight: 800;
    margin: 0;
    color: var(--main-blue);
}

.mainPage-description{
    margin-top: 0;
    font-family: "Montserrat Regular", sans-serif;
    font-weight: 400;
    font-size: 21px;
    line-height: 32px;
    position: relative;
    left: 0;
    transition: opacity 1s, left 1s;
}

.mainPage-head{
    grid-area: head;
    margin-bottom: 32px;
    position: relative;
    top: 0;
    transition: opacity 1s, top 1s;
}

.mainPage-description{
    grid-area: desc;
    margin-bottom: 72px;
}

.mainPage-description-link {
    color: var(--main-blue);
}

.mainPage-timer {
    margin-bottom: 64px;
    grid-area: timer;
    position: relative;
    left: 0;
    transition: opacity 1s, left 1s;
}

.mainPage-address{
    margin-bottom: 32px;
    grid-area: address;
    position: relative;
    width: 100%;
    top: 0;
    transition: opacity 1s, top 1s;
}

.mainPage-key{
    display: flex;
    align-items: flex-end;
    gap: 24px;
    grid-area: key;
    position: relative;
    top: 0;
    transition: opacity 1s, top 1s;
}

.mainPage-input{
    width: 100%;
    max-width: 540px;
}

.mainPage-button{
    width: 253px;
}

.capsule{
    display: flex;
    align-items: center;
    grid-area: img;
    position: relative;
    top: 0;
    transition: opacity 1s, top 1s;
    user-select: none;
}

.capsule-img{
    width: 589px;
    -webkit-user-drag: none;
}

/*On modal open*/
.container-close .mainPage-key,
.container-close .mainPage-address{
    opacity: 0;
    top: 100px;
}

.container-close .mainPage-timer,
.container-close .mainPage-description{
    opacity: 0;
    left: -100px;
}

.container-close .mainPage-head,
.container-close .capsule{
    opacity: 0;
    top: -100px;

}

.container-close{
    overflow: hidden;
}

@media (max-width: 1450px) {
    .container {
        grid-template-columns: 304px 304px auto 395px;
    }

    .capsule-img {
        width: 395px;
    }
}

@media (max-width: 1067px) {
    .container{
        padding: 45px 32px 21px 32px ;
        grid-template-areas:
            "head head"
            "desc desc"
            "timer img"
            "address address"
            "key key";
        grid-template-columns: 1fr 1fr;
    }

    .mainPage-timer{
        margin-bottom: 48px;
        display: flex;
        align-items: center;
    }

    .mainPage-input{
        max-width: none;
    }

    .capsule-img {
        width: 395px;
        height: 499px;
        margin: 24px 0;
    }

    .mainPage-lang{
        top: 45px;
        right: 32px;
    }

    .header{
        max-width: calc(100% - 76px);
    }

    .mainPage-description{
        margin-bottom: 32px;
    }
}

@media (max-width: 670px) {
    .container{
        padding: 48px 20px 20px 20px ;
    }
}

@media (max-width: 620px) {
    .logo {
        width: 54px;
        height: 20px;
        margin-bottom: 16px;
    }

    .header {
        font-size: 48px;
        line-height: 56px;
    }

    .mainPage-subHeader{
        font-size: 21px;
        line-height: 27px;
        max-width: calc(100% - 76px);
    }

    .mainPage-description {
        font-size: 18px;
        line-height: 24px;
    }

    .mainPage-key{
        flex-direction: column;
        gap: 32px;
    }

    .mainPage-button{
        width: 100%;
    }

    .capsule-img {
        width: 213px;
        height: 280px;
        margin: 0;
    }

    .mainPage-lang{
        top: 48px;
        right: 20px;
    }
}

@media (max-width: 400px) {
    .header {
        font-size: 40px;
        line-height: 50px;
    }

    .capsule-img{
        width: 150px;
        height: 200px;
    }
}
