*{
    --main-blue: #5530BE;
    --main-black: #212121;
    --main-white: #ffffff;
    --main-disabled: #B9B4C6;

    color: var(--main-black);
}

.layout{
    background-image: url("assets/background.png");
    background-repeat: no-repeat;
    background-position: right;
    background-size: cover;
    min-height: 100vh;
}

.stop-scroll{
    -webkit-overflow-scrolling: touch;
    overflow: hidden;
    overflow-x: hidden;
    overflow-y: hidden;
    width: 100vw;
    height: 100vh;
    position: fixed;
}

@font-face {
    font-family: 'Montserrat Regular';
    src: local("Montserrat Regular"), url(./fonts/Montserrat-Regular.ttf) format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat Medium';
    src: local("Montserrat Medium"), url(./fonts/Montserrat-Medium.ttf) format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat SemiBold';
    src: local("Montserrat SemiBold"), url(./fonts/Montserrat-SemiBold.ttf) format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat ExtraBold';
    src: local("Montserrat ExtraBold"), url(./fonts/Montserrat-ExtraBold.ttf) format('truetype');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}
