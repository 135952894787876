.button{
    background: var(--main-blue);
    height: 59px;
    width: 100%;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Montserrat Medium", sans-serif;
    font-size: 15px;
    line-height: 32px;
    color: var(--main-white);
    user-select: none;
}

.button:disabled {
    background: var(--main-disabled);
}

.button:focus{
    outline: none;
}

.button-loader {
    width: 24px;
    height: 24px;
    animation: spin 2s infinite linear ;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(359deg);
    }
}
