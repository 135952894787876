.select-container{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    outline: none;
}

.select {
    border: 1px solid var(--main-blue);
    width: 32px;
    height: 18px;
    position: relative;
    padding: 19px 19px 19px 16px;
    font-size: 15px;
    line-height: 18px;
    color: var(--main-blue);
    font-family: "Montserrat Regular", sans-serif;
    cursor: pointer;
    margin-bottom: 4px;
    outline: none;
    user-select: none;
}

.select-menu{
    display: block;
    z-index: 2;
    width: 67px;

    height: 100%;
    border: 1px solid var(--main-blue);
    list-style: none;
    padding: 0;
    margin: 0;
    outline: none;
    transition: height .5s;
    overflow: hidden;
}

.select-menu-item{
    margin: 0;
    font-size: 15px;
    line-height: 18px;
    color: var(--main-blue);
    cursor: pointer;
    outline: none;
    transition: height .4s;
    padding: 19px 24px;
    border-bottom: 1px solid var(--main-blue);
    opacity: 1;
    user-select: none;
}

.select-menu-item:last-child{
    border-bottom: none;
    cursor: pointer;
}

.select-menu-item:hover {
    background: rgba(85, 48, 190, 0.08);
}

.select::after {
    position: absolute;
    content: '';
    background-image: url(/src/assets/selectArrow.svg);
    top: calc(50% - 2px);
    right: 16px;
    width: 8px;
    height: 5px;
}

@media (max-width: 620px){
    .select{
        border: none;
        padding: 4px 15px 4px 0;
        width: 56px;
        display: flex;
        justify-content: flex-end;
        margin-bottom: 0;
    }

    .select::after {
        right: 4px;
    }
}
