.input{
    width: 100%;
}

.input-label{
    font-family: "Montserrat SemiBold", sans-serif;
    color: var(--main-blue);
    margin-bottom: 8px;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    user-select: none;
}

.input-input{
    height: 25px;
    width: calc(100% - 42px);
    backdrop-filter: blur(43px);
    background: rgba(255, 255, 255, 0.01);
    border: 1px solid var(--main-blue);
    padding: 16px 20px;
    font-family: "Montserrat Regular", sans-serif;
    font-size: 15px;
    line-height: 18px;
    color: var(--main-blue);
    -webkit-appearance: none;
    -webkit-border-radius: 0;
    border-radius: 0;
    box-shadow: none;
}

.input-input::placeholder{
    color: var(--main-blue);
}

.input-input:focus{
    outline: none;
}
