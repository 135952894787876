.capsuleModal{
    width: 100vw;
    height: 100vh;
    background: var(--main-black);
    opacity: 0.9;
    backdrop-filter: blur(32px);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
    transition: opacity .45s, z-index .45s;
    display: flex;
    align-items: center;
}

.capsuleModal-close{
    opacity: 0;
    z-index: -1;
}

.capsuleModal-block{
    position: relative;
    left: 0;
    display: inline-block;
    font-family: "Montserrat ExtraBold", sans-serif;
    font-weight: 800;
    font-size: 130px;
    line-height: 260px;
    white-space: nowrap;
}

.capsuleModal-text1{
    display: inline-block;
    position: relative;
    color: var(--main-white);
    left: -100%;
    top:0;
    opacity: 0;
}

.capsuleModal-text2{
    color: var(--main-white);
    position: absolute;
    left: 100%;
    top: 0;
    padding-left: 0.3em;
}

.capsuleModal-text3{
    position: absolute;
    color: var(--main-blue);
    white-space: nowrap;
    opacity: 0;
    margin-left: 0.4em;
}

.capsuleModal-content{
    position: absolute;
    opacity: 1;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    max-height: calc(100vh - 80px);
    padding: 40px 0;
    overflow: auto;
}

.capsuleModal-cross{
    position: fixed;
    width: 35px;
    height: 35px;
    top: 42px;
    right: 42px;
    cursor: pointer;
    background: url(/src/assets/cross.svg) no-repeat center;
    background-size: 22px;
    border: none;
    padding: 0;
    margin: 0;
    opacity: 1;
    z-index: 5;
}

.capsuleModal-content-text{
    color: var(--main-white);
    font-size: 24px;
    line-height: 32px;
    font-weight: 500;
    font-family: "Montserrat Medium", sans-serif;
    max-width: 1000px;
    width: calc(100% - 60px);
    white-space: pre-wrap;
    overflow-wrap: break-word;
}

.capsuleModal-content-video{
    max-width: calc(100% - 416px);
    height: calc(100% - 324px);
}

.capsuleModal-files{
    padding: 40px;
    width: 100%;

}

.capsuleModal-files-list{
    list-style: none;
    margin: 0;
    padding: 10px 0;
    background-color: var(--main-white);
    border-radius: 5px;
}

.capsuleModal-files-item{
    cursor: pointer;
    padding: 10px 10px;
}

.capsuleModal-files-item:hover{
    background: rgba(85, 48, 190, 0.08);
}

.capsuleModal-animate .capsuleModal-text1{
    animation: 5.6s ease-out text1 forwards;
}

.capsuleModal-animate .capsuleModal-text3{
    animation: 5.6s ease-out text3 forwards;
}

.capsuleModal-animate .capsuleModal-content{
    animation: 6s linear content forwards;
}

.capsuleModal-animate .capsuleModal-cross{
    animation: 6s linear content forwards;
}

@media (max-width: 1900px) {
    .capsuleModal-text3{
        display: block;
        margin-left: 0;
    }

    .capsuleModal-block{
        padding-bottom: 312px;
    }
}

@media (max-width: 1440px){
    .capsuleModal-block{
        font-size: 120px;
    }
}

@media (max-width: 1100px) {
    .capsuleModal-block{
        font-size: 70px;
        line-height: 85px;
        padding-bottom: 100px;
    }

    .capsuleModal-content-video{
        max-width: 100%;
        height: calc(100% - 160px);
    }

    .capsuleModal-content-text{
        padding: 0 50px 0 30px;
    }

    .capsuleModal-cross{
        top: 45px;
        right: 14px;
    }
}

@media (max-width: 768px) {
    .capsuleModal-block{
        display: flex;
        justify-content: center;
        font-size: 38px;
        line-height: 52px;
        white-space: normal;
        padding-left: 21px;
        padding-bottom: 0;
        opacity: 0;
    }

    .capsuleModal-animate .capsuleModal-block {
        animation: 4s ease-out blockMobile forwards;
    }

    .capsuleModal-text1{
        /*animation: none;*/
        left: 0;
        opacity: 1;
    }

    .capsuleModal-text2{
        left: 0;
        position: relative;
        animation: none;
        opacity: 1;
    }

    .capsuleModal-text3{
        position: relative;
        margin-left: 0;
    }

    .capsuleModal-content-video{
        height: calc(100% - 80px);
    }

    .capsuleModal-animate .capsuleModal-text1{
        animation: none;
    }

    .capsuleModal-animate .capsuleModal-text3{
        animation: 4s ease-out text3 forwards;
    }

    .capsuleModal-content{
        padding: 20px 0;
        max-height: calc(100vh - 40px);
    }

    .capsuleModal-content-text{
        padding: 0 40px 0 20px;
    }

    .capsuleModal-cross{
        top: 30px;
        right: 14px;
    }
}

@media (max-width: 480px) {
    .capsuleModal-block{
        font-size: 32px;
        line-height: 46px;
        padding-bottom: 120px;
    }

    .capsuleModal-cross{
        top: 30px;
        right: 14px;
        background-size: 20px;
    }

    .capsuleModal-content{
        padding: 20px 0 120px 0;
        max-height: calc(100vh - 140px);
    }
}

@keyframes text1 {
    0%{
        left: 100vw;
        opacity: 1;
    }

    9%{
        left: 100vw;
        opacity: 1;
    }

    65%{
        left: -100%;
        opacity: 1;
    }

    82%{
        left:-100%;
        opacity: 1;
    }

    85%{
        opacity: 1;
    }

    100%{
        left: -100%;
        opacity: 0;
    }
}

@keyframes blockMobile {
    0% {
        opacity: 1;
        left: 100%;
    }

    9%{
        opacity: 1;
        left: 100%;
    }

    65% {
        opacity: 1;
        left: 0;
    }

    82%{
        opacity: 1;
        left: 0;
    }

    100%{
        opacity: 0;
    }
}

@keyframes text3 {
    0%{
        opacity: 0;
    }

    66%{
        opacity: 0;
    }

    82%{
        opacity: 1;
    }

    100%{
        opacity: 0;
    }
}

@keyframes content {
    0%{
        opacity: 0;
    }
    93%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}
